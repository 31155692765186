import React, { useState } from "react";
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  function handleSubmit(e) {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    const templateParams = {
      from_name: name,
      message: message,
      email: email
    }

    emailjs.send("service_3ino6hf", "template_9hts2eg", templateParams, "EsCiLTmAuitWPOFwR")
      .then((response) => {
        setName('');
        setEmail('');
        setMessage('');
        
        toast.success("Email enviado!");
        setTimeout(() => {
          window.location.reload();
        }, 800);
      })
      .catch((e) => {
        console.log(e);
      })
   }

   function validateInputs() {
    if(name === '' || email === '' || message === '') {
      toast.error("Preencha todos os campos!");
      return false;
    }
    return true;
  }

  return (
    <section
      className="
    bg-gray-800 shadow-sm
        text-white py-10 min-h-screen"
      id="contact"
    >
      <div
        className="
            container text-center md:w-[70%] mx-auto
            md:justify-center md:items-center 
        "
      >
        <h2
          className="tracking-tight
          text-2xl lg:text-4xl font-bold border-b-[2px] md:text-3xl
          w-[200px] mx-auto border-blue-600  
          
        "
        >
          Contato
        </h2>
        <div className="justify-center items-center">
          <p className="mt-5 md:text-xl">
            Entre em contato pelo whatsapp, ou me envie um email.
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center center mt-8 ">
        <a
          href="https://wa.me/message/7PUPDXBNVORFM1"
          className="
            text-white bg-green-700 
            hover:bg-green-800 focus:ring-4 
            focus:ring-blue-300 font-medium 
            rounded-lg text-sm px-3 py-2 
            text-center inline-flex items-center  
            dark:bg-green-600 
            dark:hover:bg-green-700 
            dark:focus:ring-green-800"
        >
          Contato no WhatsApp
          <svg
            className="mr-1 ml-2 h-4 w-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </a>
      </div>

      <div className="flex items-center justify-center w-full">
        <div
          className="
            container flex 
            sm:flex-nowrap flex-wrap 
            md:items-center 
            md:justify-center
            lg:items-center 
            lg:justify-center
            justify-center items-center 
            lg:ml-auto lg:mr-auto
            w-full
          "
        >
          <div
            className="
              lg:w-2/3 md:w-2/3 md:items-center 
              md:justify-center lg:items-center justify-center
              rounded-lg overflow-hidden
              sm:mr-10 p-10 flex lg:justify-center lg:ml-auto lg:mr-auto
              "
          >
            <form
              onSubmit={handleSubmit}
              className="
                lg:w-1/2 md:w-2/3 flex flex-col md:ml-auto 
                w-full md:py-4 mt-2 md:mt-0 md:center
                items-center justify-center lg:ml-auto lg:mr-auto
              "
            >
              <div className="mb-4 items-center justify-center">
                <label
                  htmlFor="name"
                  className="leading-7 text-sm text-gray-400"
                >
                  Nome
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={(e) =>setName(e.target.value)}
                  placeholder="Insira aqui seu nome"
                  className=" h-16
                    w-full bg-gray-800 rounded border 
                    border-gray-700 focus:border-indigo-500 
                    focus:ring-2 focus:ring-indigo-900 text-base 
                    outline-none text-gray-100 py-1 px-3 leading-8 
                    transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="email"
                  className="leading-7 text-sm text-gray-400"
                >
                  Email
                </label>
                <input
                  onChange={(e) =>setEmail(e.target.value)}
                  type="email"
                  id="email"
                  placeholder="Insira aqui seu email"
                  name="email"
                  className="h-16
                    w-full bg-gray-800 rounded border 
                    border-gray-700 focus:border-indigo-500 
                    focus:ring-2 focus:ring-indigo-900 text-base 
                    outline-none text-gray-100 py-1 px-3 leading-8 
                    transition-colors duration-200 ease-in-out"
                />
              </div>
              <div className="relative mb-4">
                <label
                  htmlFor="message"
                  className="leading-7 text-sm text-gray-400"
                  
                >
                  Mensagem
                </label>
                <textarea
                  onChange={(e) =>setMessage(e.target.value)}
                  id="message"
                  name="message"
                  placeholder="Insira aqui sua mensagem"
                  className="
                    w-full bg-gray-800 rounded border 
                    border-gray-700 focus:border-indigo-500 
                    focus:ring-2 focus:ring-indigo-900 h-32 text-base 
                    outline-none text-gray-100 py-1 px-3 resize-none 
                    leading-6 transition-colors duration-200 ease-in-out"
                />
              </div>

              <button type="submit">
                <a
                  
                  className="
                    text-white bg-blue-700 hover:bg-blue-800 
                    focus:ring-4 focus:ring-blue-300 font-medium 
                    rounded-lg text-sm px-3 py-2 text-center inline-flex 
                    items-center  dark:bg-blue-600 dark:hover:bg-blue-700 
                    dark:focus:ring-blue-800 "
                >
                  Enviar email
                  <svg
                    className="-mr-1 ml-2 h-4 w-4 "
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </a>
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;