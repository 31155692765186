import direitoConsumidor from "../../assets/direitoConsumidor.jpg";
import direitoCivil from "../../assets/direitoCivil.jpg";
import direitoTrabalho from "../../assets/direitoTrabalho2.png";
import compiance from "../../assets/compiance.png";
import work from "../../assets/work2.png";
import previdence from "../../assets/previdenciario.png";

const data = [
  {
    img: direitoTrabalho,
    title: "Direito do trabalho",
    desc: "Atuação preventiva em assessoria e consultoria, além de atuação contenciosa judicial ou administrativa.",
    alt: "Imagem do direito do trabalho",
  },
  {
    img: compiance,
    title: "Compliance",
    desc: "Auxiliando empresas na implementação e gestão de programas de conformidade com as leis e regulamentações aplicáveis às suas atividades.",
    alt: "Imagem de compliance",
  },
  {
    img: direitoCivil,
    title: "Direito Civil",
    desc: "Atuação em questões que envolvem relações jurídicas entre pessoas físicas e jurídicas. Isso pode incluir questões relacionadas a contratos, responsabilidade civil, direitos de propriedade, família e sucessões, obrigações e indenizações.",
    alt: "Imagem direito civil",
  },
  {
    img: direitoConsumidor,
    title: "Direito do consumidor",
    desc: "Atuação na defesa do consumidor ou do fornecedor, além de realizar consultorias jurídicas.Além de atuar preventivamente junto às empresas, trazendo aos seus assessorados muitos benefícios.",
    alt: "Imagem do direito do consumidor",
  },
  {
    img: previdence,
    title: "Direito Previdenciário",
    desc: "Trabalho garantindo os direitos dos segurados da Previdência Social. As atividades incluem auxiliar em processos administrativos, revisões de benefícios, atuar em processos judiciais e orientar os segurados.",
    alt: "Imagem direito previdenciário",
  },
  {
    img: work,
    title: "Demandas Extrajudiciais",
    desc: "Oferecemos consultoria jurídica, revisando e rescindindo contratos, emitindo pareceres jurídicos e realizando diligências em cartórios. Também representamos clientes em reuniões e audiências, dentre outras atividades.",
    alt: "Imagem demandas extrajudiciais",
  },
];

export default function index() {
  return (
    <div
      className="
        container mx-auto grid 
        md:grid-cols-1 
        lg:grid-cols-2 
        items-center justify-center 
        md:justify-center 
        md:w-4/5 md:items-center
        md:p-40
        text-center md:text-start 
        lg:ml-auto lg:mr-auto 
        lg:m-auto lg:w-full
        
        "
    >
      {data.map((item, index) => (
        <div
          key={index}
          className="
            hero-info lg:pb-2 md:pb-0 
            lg:w-full md:w-4/3 p-2 w-full h-full "
        >
          <div className="max-w-2xl mx-auto">
            <div className="
              bg-white shadow-md border 
              border-gray-200 rounded-lg 
              max-w-sm dark:bg-gray-800 
              dark:border-gray-700 
              duration-500
              ease-in-out
              transform
              hover:-translate-y-1 hover:scale-95 
              "
            >
              <a>
                <img 
                  className="rounded-t-lg" 
                  src={item.img} alt={item.alt} 
                />
              </a>
              <div className="p-5 lg:p-10">
                <a>
                  <h5 className="
                    text-gray-900 font-bold 
                    text-2xl tracking-tight mb-2 
                    dark:text-white text-md 
                    lg:text-lg md:text-md"
                  >
                    {item.title}
                  </h5>
                </a>
                <p className="
                  font-normal text-gray-700 
                  mb-3 dark:text-gray-400
                  text-md lg:text-xl md:text-lg"
                >
                  {item.desc}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
