import React from "react";

import advocate from 
  "../../assets/advocate-removebg-preview.png";

import gmail from "../../assets/gmail.png";
import whatsapp from "../../assets/whatsapp.png";

export default function index() {
  return (
    <section className="bg-primary text-white py-2">
      <div
        className="
        container mx-auto grid md:grid-cols-2 
        items-center justify-center md:justify-between 
        text-center md:text-start
        "
      >
        <div className="
          hero-info lg:pb-2 
          md:pb-0"
        >
          <h1 className="
            text-2xl lg:text-5xl 
            lg:mt-16 md:mt-16"
          >
            <span className="text-accent">
            Dr Cleisson Oliveira, advogado em Rio Verde Goiás atuante nas áreas de direito do trabalho, previdenciário, consumidor e civil.
            </span> 
            <br />
          </h1>
          <p className="py-6">
          Meu objetivo é ajudar meus clientes a resolverem seus problemas jurídicos de forma justa e eficiente.
          </p>
          <p className="lg:py-6 md:py-6">
            OAB/GO 63.812
          </p>

          <div className="
            flex lg:justify-start 
            justify-center lg:mt-4"
          >
            <a
              className="py-2 mr-4 duration-500
              ease-in-out
              transform
              hover:-translate-y-1 hover:scale-125
              "
              
              href="https://wa.me/message/7PUPDXBNVORFM1"
            > 
              <img 
                src={whatsapp} 
                alt="imagem whatsapp" 
                className="w-8 sm:p-1 lg:w-9 md:w-9" 
               
              /> 
            </a>
            <a
              className="py-2 mr-4 duration-500
              ease-in-out
              transform
              hover:-translate-y-1 hover:scale-125"
              href="mailto:contato@cleisson.adv.br"
            > 
              <img 
                src={gmail} 
                alt="imagem gmail" 
                className="sm:w-6 sm:p-1 lg:w-9 w-8 md:w-9" 
              /> 
            </a>

          </div>
        </div>
        <div className="
          flex justify-center 
          items-center lg:w-auto 
          w-4/5"
        >
          <img
            src={advocate}
            alt="code illustration"
            className="
              lg:w-auto 
              lg:mb-16 
              lg:m-auto 
              justify-center
              sm:ml-3
              lg:h-auto
              md:h-auto
            "
          />
        </div>
      </div>
    </section>
  );
}
