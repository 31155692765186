import React from "react";
import logo from "../../assets/marcaDgua.png";

const Header = () => {

  return (
    <header className="
      flex justify-between 
      px-5 py-1 bg-primary 
      text-white"
    >
      <a
        href="/"
        className="
          logo text-2xl font-bold 
          text-accent justify-center 
          flex items-center"
      >
        <img
          src={logo}
          alt="Imagem logo"
          className="
            w-12 h-12 duration-500
            ease-in-out
            transform
            hover:-translate-y-1 hover:scale-110"
        />
      </a>
      <nav className="hidden md:block">
        <ul className="flex">
          <li
            className=" 
                flex items-center 
                justify-center 
                text-center 
                duration-500
                ease-in-out
                transform
                hover:-translate-y-1 
                hover:scale-110"
          >
            <a href="/">Institucional</a>
          </li>
          <li
            className="
              flex items-center 
              justify-center 
              text-center 
              duration-500
              ease-in-out
              transform
              hover:-translate-y-1 hover:scale-110"
          >
            <a href="/">Sobre</a>
          </li>
          <li
            className="
              flex 
              items-center 
              justify-center 
              text-center 
              duration-500
              ease-in-out
              transform
              hover:-translate-y-1 
              hover:scale-110"
          >
            <a href="/#about">Consultoria</a>
          </li>
          <li
            className="
              flex items-center 
              justify-center 
              text-center 
              transition
              duration-500
              ease-in-out
              transform
              hover:-translate-y-1 
              hover:scale-110"
          >
            <a href="/#contact">Contato</a>
          </li>
          <li
            className="transition
                duration-500
                ease-in-out
                transform
                hover:-translate-y-1 
                hover:scale-110 
                hover:bg-gray-50"
          ></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
