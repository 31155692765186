import React from "react";

import gmail from "../../assets/gmail.png";
import linkedin from "../../assets/linkedin.png";
import telegram from "../../assets/telegram.png";
import whatsapp from "../../assets/whatsapp.png";
import instagram from "../../assets/instagram.png";
import discord from "../../assets/discord.png";

import hero from "../../assets/hero.png";

const socials = [
  {
    img: linkedin,
    href: "https://www.linkedin.com/in/cleisson-oliveira-4a6578237/",
    alt: "imagem do linkedin",
  },
  {
    img: telegram,
    href: "https://t.me/cleissonop",
    alt: "imagem do telegram",
  },
  {
    img: gmail,
    href: "mailto:contato@cleisson.adv.br",
    alt: "imagem do gmail",
  },
  {
    img: whatsapp,
    href: "https://wa.me/message/7PUPDXBNVORFM1",
    alt: "imagem do whatsapp",
  },
  {
    img: instagram,
    href: "https://www.instagram.com/cleisson.adv/",
    alt: "imagem do instagram",
  },
  {
    img: discord,
    href: "https://discordapp.com/channels/@me/Cleisson#2439",
    alt: "imagem do discord",
  },
];

export default function index() {
  return (
    <div className="py-2 text-center bg-primary text-white text-sm">
      <div className=" grid grid-cols-2 mt-8">
        <div className="flex justify-center">
          <img
            src={hero}
            className="
              lg:w-1/4 ml-2 md:w-2/4
              duration-500
              ease-in-out
              transform
              hover:-translate-y-1 hover:scale-125
            "
          />
        </div>
        <div className="flex justify-center mt-12">
          {socials.map((element, index) => (
            <div key={index}>
              <a
                className="duration-500
                ease-in-out
                transform
                hover:-translate-y-1 hover:scale-125"
                href={element.href}
              >
                <img
                  src={element.img}
                  className="sm:w-6 sm:p-1 lg:w-9 w-5 m-1 md:w-9
                  duration-500
                  ease-in-out
                  transform
                  hover:-translate-y-1 hover:scale-125"
                />
              </a>
            </div>
          ))}
        </div>
      </div>

      <div className="mb-1 mt-16">
        <p>
          &copy; 2023 feito com 💜 por 
          <span 
            href="https://www.github.com/freaklucas"
            className=
              "text-purple-500 hover:text-purple-700
              cursor-pointer ml-1
              "
          > 
             freaklucas
          </span>
        </p>
      </div>
    </div>
  );
}
