import React from "react";
import Card from '../Card/index.jsx';

export default function index() {
  return (
    <section className="
      bg-gray-800 
      text-white py-20"
      id="about"
    >
      <div
        className="container mx-auto 
        items-center justify-center 
        md:justify-between 
        text-center md:text-start"
      >
        <div className="
          hero-info lg:pb-2 
          md:pb-0 text-center"
        >
        {/* <h2
          className="
          tracking-tight
          text-2xl lg:text-4xl 
          font-bold border-b-[2px] 
          md:text-3xl
          w-[200px] mx-auto 
          border-blue-600  mb-18 sm:mb-6
          
        "
        >
          Sobre
        </h2> */}
          <h1
            className="
              text-center 
              lg:text-4xl 
              text-2xl
              mb-4 mt-12
            "
          >
            Áreas de atuação

          </h1>

          <p className="mt-12">
          Entre em contato para receber um atendimento.
          </p>
        </div>
      </div>
      <Card />
    </section>
  );
}
