import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css";

import './App.css';
import Header from './components/Header/';
import Footer from './components/Footer/';
import About from './components/About/';
import Dashboard from './components/Dashboard/DashboardFirst';

import Home from './page/Home';

import { Routes, Route, Navigate } from "react-router-dom";


function App() {
  return (
    <>
     <ToastContainer autoClose={3000} />
    <Header />
    <Routes>

      <Route path="/" element={<Home />}/>
      <Route path="/about" element={<About />}/>
        {/* <Route path="/dashboard" element={<Dashboard />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
    </Routes>

    <Footer />
    </>
  );
}

export default App;
